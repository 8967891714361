// CONTACT FORM JS

(function($) {
  $('#emp-contact-form').submit(function(event) {
    event.preventDefault();

    // Get data from form and store it
    var empSignupEMAIL = $('#emp-email-input').val();
    var empSignupNAME = $('#emp-name-input').val();
    var empSignupORG = $('#emp-org-input').val();
    var empSignupMESSAGE = $('#emp-message-input').val();

    // Create JSON variable of retreived data
    var empSignupData = {
      'email': empSignupEMAIL,
      'name': empSignupNAME,
      'organization': empSignupORG,
      'message': empSignupMESSAGE
    };

    // Send data to PHP script via .ajax() of jQuery
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/wp-content/themes/emp-theme/templates/mailchimp-send.php',
      data: empSignupData,
      success: function (results) {
        $('#emp-name-input').hide();
        $('#emp-org-input').hide();
        $('#emp-email-input').hide();
        $('#emp-message-input').hide();
        $('.emp-contact-footer-subheading').hide();
        $('.emp-contact-footer-input-row-4 .button-primary').hide();
        $('#emp-signup-result').text('Thanks for contacting the EMP team with your feedback. We\'ll get back to you soon.');
      },
      error: function (results) {
        $('#emp-signup-result').html('<p>Sorry but we were unable to send this message. Perhaps you misspelled your email or our server might have had a glitch? If you try again and continue to have problems, please contact <a href="mailto:jaskin@nacubo.org">jaskin@nacubo.org</a> directly.</p>');
      }
    });
  });
})(jQuery);
